import React from 'react'
import Layout from '../layouts/simple.js'
import { Helmet } from 'react-helmet'

const Contact = () => {
    return (
        <div className="page">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Dundas Interiors - Your Montana Herman Miller Dealer</title>
            <link rel="canonical" href="dundasinteriors.com/contact" />
        </Helmet>
        <Layout>
            <section className="hero">
                <div className="hero-background">
                    <div className="container">
                        <div className="hero-content">
                            <h1>Drop Us A Line</h1>
                            <h3>Our friendly Helena, MT based team can't wait to hear from you. Contact us today to see how we can help you.</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section id="form" className="py-60">
                <div className="container">
                    <div className="mw-600 centered">
                    <div className="column-two pb-60">
                            <div>
                                3730 N Montana Ave<br />
                                Helena, MT 59602
                            </div>
                            <div>
                                <a href='mailto:contact_us@dundasinteriors.com' className="link-blank">contact_us@dundasinteriors.com</a><br />
                                <a href='tel:4064423448' className="link-blank">(406) 442-3448</a>
                            </div>
                        </div>
                        <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                            <p>
                                <input type="hidden" name="form-name" value="contact" />
                            </p>
                            <p>
                                <label className='w-100'>Your Name: <input type="text" className='form-control me-0 w-100 rounded-0 border-black' name="name" /></label>   
                            </p>
                            <p>
                                <label className='w-100'>Your Email: <input type="email" className='form-control me-0 w-100 rounded-0 border-black' name="email" /></label>
                            </p>
                            <p>
                                <label className='w-100'>Your Phone: <input type="tel" className='form-control me-0 w-100 rounded-0 border-black' name="phone" /></label>
                            </p>
                            <p>
                                <label className='w-100'>Message: <textarea className='form-control me-0 w-100 rounded-0 border-black' name="message"></textarea></label>
                            </p>
                            <p>
                                <button type="submit" className="button-red">Submit Form</button>
                            </p>
                        </form>
                    </div>
                </div>
            </section>
        </Layout>
        </div>
    )
}

export default Contact